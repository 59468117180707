<template>
  <div>
    <ch-layout page search>
      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
  </div>
</template>

<script>
export default {
  data(){
    return{
      // 搜索相关
      searchForm: {
        transactionType:null,
        startTime:null,
        endTime:null,
        transactionId:null,
        type:null,
        time:[],
      },
      tableList: [], // table列表
      isTableLoading: false, // table加载
      typeList:['','收入','支出'],
      transactionTypeList: ['提现', '商品订单', '服务订单', '增值服务订单']
    }
  },
  computed:{
    searchOption: function (){
      return [
        {
          type: "select", label: "交易类型", prop: "transactionType", placeholder: "请选择交易类型", labelWidth: '70px',
          name: "label", value: "value", option: [
            {value: 0, label: "提现"},
            {value: 1, label: "商品订单"},
            {value: 2, label: "服务订单"},
            {value: 3, label: "增值服务订单"},
          ]
        },
        {type: 'input', label: '订单号', prop: 'transactionId', placeholder: "请输入订单号",width: '220px'},
        {type: "time", label: "交易时间", prop: "time",},
        {
          type: "select", label: "状态：", prop: "type", placeholder: "请选择",labelWidth: '44px',
          name: "label", value: "value", option: [
            {value: 1, label: "收入"},
            {value: 2, label: "支出"},
          ]
        },
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]},

      ]
    },
    tableOption:function (){
      return [
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "交易类型", prop: "_transactionType", showOverflowTooltip: true,props:{minWidth: '100px'}},
        { column: "text", label: "交易内容", prop: "title", showOverflowTooltip: true,props:{minWidth: '100px'}},
        { column: "text", label: "订单号", prop: "transactionId", showOverflowTooltip: true,props:{minWidth: '170px'}},
        { column: "text", label: "交易时间", prop: "createTime", showOverflowTooltip: true,props:{minWidth: '100px'}},
        { column: "text", label: "交易金额", prop: "amount",sign:'元', showOverflowTooltip: true,props:{minWidth: '140px'}},
        { column: "text", label: "余额", prop: "changeAmount",sign:'元', showOverflowTooltip: true,props:{minWidth: '140px'}},
        { column: "text", label: "状态", prop: "_type",showOverflowTooltip:true,},
      ];
    },
  },
  mounted() {
    this.getTableList()
  },
  methods:{
    // 查询
    searchHandle() {
      if(this.searchForm.time && this.searchForm.time.length>0){
        this.searchForm.startTime = this.searchForm.time[0]
        this.searchForm.endTime = this.searchForm.time[1]
      }else{
        this.searchForm.startTime = null
        this.searchForm.endTime = null
      }
      this.getTableList()
    },
    // 列表数据
    async getTableList() {
      let params = {
        ...this.searchForm,
        staffId:this.$route.query.id,
      }
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/serviceStaff/getMoneyList",params).then((res) => {
        this.tableList = res.rows.map((item) => {
          return {
            ...item,
            _transactionType: this.transactionTypeList[item.transactionType],
            _type: this.typeList[item.type],
          }
        })
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },

  }
}
</script>

